<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
     
        <v-card class="card">
        <v-card-title class="heading justify-center">
        <span>Add User</span>
      </v-card-title>
      <v-card-text>
<div class="add-section text">
    <div >
      <v-form ref="form">
        <v-col>
          
        <!-- <v-row class="justify-center">
            <v-col  class="pb-0">
              <label class="add-text">Registration Number / Employee Code</label>
                <v-text-field
                    v-model="memberId"
                    dense
                    outlined
                    :rules="[rules.required]" clearable required
                ></v-text-field>
            </v-col>

        </v-row>  -->
        
         <v-row class="justify-center">   
            <v-col   class="pb-0">
            <label class="add-text">Select Tenant</label>
                <v-combobox
                    v-model="selectedtenant"
                    :items="tenantlist"
                    item-text="name"
                    item-value="tc_id"
                    outlined
                    return-object
                    dense
                    :rules="[rules.required]" clearable required
                ></v-combobox>
            </v-col>
        </v-row>
        <v-row class="justify-center">
            <v-col   class="pb-0 ">
              <label class="add-text">User Name</label>
            <v-text-field
                v-model="username"
                dense
                outlined
                :rules="[rules.required]" clearable required
            ></v-text-field>
            </v-col>
        </v-row>   
        <!-- <v-row class="justify-center">
            <v-col  class="p-btn">
            <v-btn
                small
                color="warning"
                v-on:click="checkUsername()"
                >
                Check Availability
            </v-btn>
            
            </v-col>
        </v-row>  -->
        <v-row class="justify-center" >
            <v-col  class="text-center">
                <v-btn
                depressed
                color="success"
                v-if="status"
                @click="checkUsername()"
                >
                Check Available
                </v-btn>
                <v-btn
                depressed
                 v-if="!status"
                color="success"
                @click="saveUsername()"
                >
                ADD
                </v-btn>
            </v-col>
        </v-row> 
</v-col>
      </v-form>
    </div>
</div>

  <template>
    <v-data-table
        :headers="headers"
        :items="userlist"
        :items-per-page="5"
        class="elevation-1"
    >
     <template v-slot:top>
      <v-toolbar
        flat
      >
       
    <v-dialog
          v-model="dialog"
          max-width="700px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.user"
                      label="User"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <!-- <v-combobox
                    v-model="editedItem.usertype"
                    :items="userType"
                    item-text="userType"
                    label="User Type"
                    return-object
                    filled
                    rounded
                    dense
                ></v-combobox> -->
                
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-combobox
                    v-model="editedItem.selectedtenant"
                    :items="tenantlist"
                    item-text="name"
                    item-value="tc_id"
                    outlined
                    return-object
                    dense
                    :rules="[rules.required]" clearable required
                ></v-combobox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
         </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
         mdi mdi-pencil-box
        </v-icon>
    </template>
  </v-data-table>
   
</template>
      </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard

export default {
     data: () => ({
       snackbar_msg: "",
            snackbar: false,
            color: "",
    username: "",
    tenantlist:[],
    userlist:[],
    memberId:"",
    usernamemessage:'',
    rules: {
                required: value => !!value || "Required."
                        },    
    selectedtenant:null,
    dialog: false,
    headers: [
          { text: 'User', value: 'user' },
          { text: 'User Type', value: 'usertype.userType' },
          { text: 'Registration Number/ Employee Code', value: 'regno' },
          { text: 'Edit', value: 'actions' },
        ],
    editedIndex: -1,
    editedItem: {
     userid:'',
     user: '',
     usertype: null,
     regno: '',
     selectedtenant:null,
    },
    defaultItem: {
     userid:'',
     user: '',
     usertype: null,
     regno: '',
     selectedtenant:null,
    },
    status:true,
  }),
  computed: {
    formTitle () {
      return 'Edit Item'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
 mounted() {
        this.onLoad();
    },
   methods: {
      showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
    checkUsername: function () {
        const data = {
              username: this.username,
              selectedtenant: this.selectedtenant,
            };
        axios.post("/SuperAdmin/checkUsername",data)
            .then(res => {
                //console.log("res------->"+res.data.userType)
                if (res.data.msg == "200") {
                   this.usernamemessage = res.data.message;
                   this.status = res.data.status;
                   if(res.data.code == 1)
                      this.showSnackbar("#b71c1c", res.data.message);
                    else
                      this.showSnackbar("#4CAF50", res.data.message);
                  //  console.log("res------->"+res.data.message);
                }
            })
            .catch(error => {
                window.console.log(error)
            })
    },
    saveUsername: function(){
      
       const data = {
              username: this.username,
              selectedtenant: this.selectedtenant
            };
        axios.post("/SuperAdmin/saveUsername",data)
            .then(res => {
                if (res.data.msg == "200") {
                     this.showSnackbar("#4CAF50",'Successfully created Login Usernane & Password is Same!!');
                   this.onLoad();
                   this.username=null
                }
            })
            .catch(error => {
                this.showSnackbar("#b71c1c", "Something went wrong!!!");
                window.console.log(error)
            })
       
    },
    onLoad: function(){
        axios.get("SuperAdmin/fetchEmployeeType")
            .then(res => {
                //console.log("res------->"+res.data.userType)
                if (res.data.msg == "200") {
                    this.tenantlist = res.data.userType,
                    this.userlist = res.data.userlist
                }
            })
            .catch(error => {
                window.console.log(error)
         })
    },
    editItem : function(item) {
      this.editedIndex = this.userlist.indexOf(item);
      console.log("in edit item----------",this.editedIndex)
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      console.log("in edit item----------",this.editedItem)
      console.log(this.dialog)
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save () {
        axios.post("/SuperAdmin/updateUtype",this.editedItem)
            .then(res => {
                //console.log("res------->"+res.data.userType)
                if (res.data.msg == "200") {
                   this.showSnackbar("#4CAF50","Successfully updated Login  Details!!");
                   this.onLoad();
                }
            })
            .catch(error => {
                this.showSnackbar("#b71c1c", "Something went wrong!!!");
                window.console.log(error)
            })
      this.close()
    },
  }
}
</script>
<style scoped>
    .heading {
        background-color: #3f51b5;
        padding: 0.3rem;
        color: white;
    }
    .v-icon{
        cursor: pointer;
    }
.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}
.p-btn{
  padding: 0px 12px 12px 12px;
}
</style>




